var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"91a49f164001eee093eba8cc5a304ee4df2797e8"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from "@sentry/browser";

import packageInfo from "./package.json";

const projectName = process.env.NEXT_PUBLIC_SENTRY_PROJECT || "ecommerce";

export const sentryInitDefaults = {
  debug: JSON.parse(process.env.NEXT_PUBLIC_SENTRY_DEBUG!),
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: !!process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
  ignoreErrors: [
    "Prop `%s` did not match. Server: %s Client: %s%s className",
    "Expected server HTML to contain a matching",
    "ResizeObserver loop limit exceeded",
    "Error occurred prerendering page",
    "ServerParseError: Unexpected token < in JSON at position 0",
    "Client network socket disconnected before secure TLS connection was established",
    'DeprecationWarning: Use of deprecated folder mapping "./" in the "exports"',
    "[Network error]: FetchError: request to",
    "Non-Error promise rejection captured",
  ],
  integrations: [new Sentry.Replay()],
  release: `${projectName}@${packageInfo.version}`,
  tracesSampleRate: 1.0,
};
